import {
  Box,
  Button,
  FormControl,
  Link,
  TextField,
  Typography,
  useMediaQuery,
  Slider,
  Backdrop,
  CircularProgress,
  Modal,
  IconButton,
  FormLabel,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  Dispatch,
} from "react";
import "./styles.scss";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import "react-image-gallery/styles/css/image-gallery.css";
//import ImageGallery from "react-image-gallery";

//import LocationIcon from "assets/images/location.png";
import leftarrow from "assets/images/myofficetrailer/right_yellow.png";
//import ThumbsImg from "assets/images/likeMe.png";

// import msgg from "assets/images/details-page/msgg.png";
// import visit from "assets/images/details-page/visit.png";

// import EquipProtection from "assets/images/details-page/EquipmentProtection.png";
// import calendarimg from "assets/images/details-page/calendar_two.png";
// import bookingimg from "assets/images/details-page/booking2.png";
// import addImg from "assets/images/details-page/Add.png";
// import Img from "assets/images/dummy-profile-pic.jpeg";
// import MainLayout from "layouts/MainLayout";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
//import AddBalance from "../../components/AddBalance/AddBalance";

/**
 * Changes for card payment holding
 * Section start
 */
import PaymentHold from "../../components/AddBalance/PaymentHold";
import CloseIcon from "@mui/icons-material/Close";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";
/**
 * Changes for card payment holding
 * Section end
 */

import {
  setModalOpen,
  setalertMessagetype,
  setalertMessage,
  // setLogInLoading,
  // setLoginStatus,
  // setaccessToken,
  // setLoginModalOpen,
  setSessionExpire,
  setReservedDates,
  setAllPartialDates,
  setEquipmentBlockedDates,
  setEquipmentBookedDates,
  setEquipmentRequestedDates,
  setPartialDatesCount,
  setPartiallyBookedDates,
  setPartiallyPendingDates,
  setRentOnWeekend,
  setCalendarDateAngle,
  // setQrCodeValue,
  // setWalletBalance,
  // SetRedirectPage
} from "redux/redux-slice";
import { EquipmentAPIs } from "../../service/api-service";
import { IApiResponseRawData, IStoreValue } from "utils/types";
import moment from "moment";
//import DatePicker from "react-multi-date-picker";
import { Calendar as NewCalendar, DateObject } from "react-multi-date-picker";
// import radioOff from "assets/images/radioOff.png";
// import radioOn from "assets/images/radioOn.png";
// import GOOGLELOCATION from "../../components/equipment-list/GoogleautoComplete";

// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import { Calendar } from "react-modern-calendar-datepicker";
import "./calendar.scss";
// import { ReadableStreamDefaultController } from "stream/web";
// import UserInfo from "./../../components/UserInfo/Userinfo";
// import CloseIcon from "@mui/icons-material/Close";
// import DetailComponent from "../../components/EquipDetails/EquipDetail";

// import DatePicker, { DateObject } from "react-multi-date-picker";

import ExtendRentalCalendar from "components/Calendar/ExtendRentalCalendar";

import momentTimeZone from "moment-timezone";
import {
  convertTimeZone,
  convertTimeZoneShortName,
  getDatesInRange,
} from "utils/commonFxns";
import CustomTimeModal from "./equipmentPopup/CustomTimeModal";
import { getAllSlotsForDate, getDateAngles } from "utils/partiallyBookedTime";

const timeArr = [
  "12:30 AM",
  "01:00 AM",
  "01:30 AM",
  "02:00 AM",
  "02:30 AM",
  "03:00 AM",
  "03:30 AM",
  "04:00 AM",
  "04:30 AM",
  "05:00 AM",
  "05:30 AM",
  "06:00 AM",
  "06:30 AM",
  "07:00 AM",
  "07:30 AM",
  "08:00 AM",
  "08:30 AM",
  "09:00 AM",
  "09:30 AM",
  "10:00 AM",
  "10:30 AM",
  "11:00 AM",
  "11:30 AM",
  "12:00 PM",
  "12:30 PM",
  "01:00 PM",
  "01:30 PM",
  "02:00 PM",
  "02:30 PM",
  "03:00 PM",
  "03:30 PM",
  "04:00 PM",
  "04:30 PM",
  "05:00 PM",
  "05:30 PM",
  "06:00 PM",
  "06:30 PM",
  "07:00 PM",
  "07:30 PM",
  "08:00 PM",
  "08:30 PM",
  "09:00 PM",
  "09:30 PM",
  "10:00 PM",
  "10:30 PM",
  "11:00 PM",
  "11:30 PM",
  "12:00 AM",
];

const myCustomLocale = {
  months: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],

  weekDays: [
    {
      name: "Sunday",
      short: "Sun",
      isWeekend: true,
    },
    {
      name: "Monday",
      short: "Mon",
    },
    {
      name: "Tuesday",
      short: "Tue",
    },
    {
      name: "Wednesday",
      short: "Wed",
    },
    {
      name: "Thursday",
      short: "Thu",
    },
    {
      name: "Friday",
      short: "Fri",
    },
    {
      name: "Saturday",
      short: "Sat",
      isWeekend: true,
    },
  ],

  weekStartingIndex: 0,

  getToday(gregorainTodayObject) {
    return gregorainTodayObject;
  },

  toNativeDate(date) {
    return new Date(date.year, date.month - 1, date.day);
  },

  getMonthLength(date) {
    return new Date(date.year, date.month, 0).getDate();
  },

  transformDigit(digit) {
    return digit;
  },

  // texts in the date picker
  nextMonth: "Next Month",
  previousMonth: "Previous Month",
  openMonthSelector: "Open Month Selector",
  openYearSelector: "Open Year Selector",
  closeMonthSelector: "Close Month Selector",
  closeYearSelector: "Close Year Selector",
  defaultPlaceholder: "Select...",
  // for input range value
  from: "from",
  to: "to",
  // used for input value when multi dates are selected
  digitSeparator: ",",
  // if your provide -2 for example, year will be 2 digited
  yearLetterSkip: 0,
  // is your language rtl or ltr?
  isRtl: false,
};

const loginstyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  p: 4,
};

let nowdata = moment().format("YYYY-MM-DD");

let trans_option_sel = null;
const ExtendRental = ({
  ActiveOrder = {},
  Result,
  setLocal = false,
  handleChatMessage,
}) => {
  const [DeviceType, setDeviceType] = useState("large");
  const inRef = useRef(null);
  const searchRef = useRef();
  const datePickerCalendar = useRef(null);
  const APIKEY = process.env.REACT_APP_GEOCODDER_KEY;
  const AuthToken = useSelector(
    (state: IStoreValue) => state.equipmentReducer.accessToken
  );
  const WalletBalance = useSelector(
    (state: IStoreValue) => state.equipmentReducer.WalletBalance
  );
  const AuthUserID = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );
  const isLoggedin = useSelector(
    (state: IStoreValue) => state.equipmentReducer.isLogin
  );

  const AuthProfileData = useSelector(
    (state: IStoreValue) => state.equipmentReducer.AuthProfileData
  );

  const [ProfilePicStatus, setProfilePicStatus] = React.useState(null);
  const [LicenceStatus, setLicenceStatus] = React.useState(null);

  const matchesMobileX = useMediaQuery("(max-width:425px)");
  const [loading, setLoading] = useState(true);
  const [OwnerInfo, setOwnerInfo] = useState(false);
  const [placeOrder, setplaceOrder] = useState(false);
  const [result, setResult] = useState({});
  const [img, setImg] = useState([]);
  const [images_gallerys, setimages_gallerys] = useState([]);

  const [Maplocation, setMaplocation] = useState("");
  const [mapLink, setMapLink] = useState("javascript:void(0)");
  const params = useParams();
  const [rescount, setRescount] = useState(0);
  const dispatch = useDispatch();
  const [deeplink, setDeeplink] = useState([]);
  //    const [ChaeckDatesBlockArr, setChaeckDatesBlockArr] = useState([]);

  //Display States for extend
  const [ExStartDate, setExStartDate] = useState("");
  const [ExEndDate, setExEndDate] = useState("");
  const [ExStTime, setExStTime] = useState("08:00 AM");
  const [ExEnTime, setExEnTime] = useState("05:00 PM");

  // console.log("ExEndDate", ExEndDate);
  const [rentStartDate, setrentStartDate] = useState("");
  const [rentEndDate, setrentEndDate] = useState("");
  const [startTime, setStartTime] = useState("08:00 AM");
  const [endTime, setEndTime] = useState("05:00 PM");
  const [rentPrice, setRentPrice] = useState(null);
  const [securityDeposit, setSecurityDeposit] = useState(0);
  const [equipProtection, setEquipProtection] = useState(0);
  const [rentalTotal, setRentalTotal] = useState(0);
  const [chooseTransport, setchooseTransport] = React.useState(null);
  const [transportTotal, settransportTotal] = useState(0);

  /**
   * Changes for card payment holding
   * Section start
   */
  //use for pay order request amount with card
  const [payWithCard, setpayWithCard] = React.useState(false);
  const [holdAmount, setholdAmount] = React.useState(0);
  const [orderRequestData, setorderRequestData] = React.useState({});

  /**
   * Changes for card payment holding
   * Section end
   */

  // const [values, setValues] = useState(blockdate);
  const [mobilecalendarstartDate, setmobilecalendarstartDate] = useState(null);
  const [mobilecalendarendDate, setmobilecalendarendDate] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [Distance, setDistance] = useState(0);
  const [TotalDays, setTotalDays] = useState("0");
  const [grandTotal, setgrandTotal] = useState(0);
  const [deliveryPrice, setdeliveryPrice] = useState(0);
  const [FullAddress, setFullAddress] = useState({
    city: "",
    state: "",
    country: "",
    postal_code: "",
    address: "",
  });
  const [deliveryerror, setdeliveryerror] = useState(false);
  const [checkoutsection, setcheckoutsection] = useState(false);
  const [proceedpaysection, setproceedpaysection] = useState(false);

  const [transportcalculating, settransportcalculating] = React.useState(false);
  const defaultdeliveryLocation = {
    lat: 39.321980897034734,
    lng: -111.10149320091872,
  };
  const [deliveryLocation, setdeliveryLocation] = React.useState(
    defaultdeliveryLocation
  );
  const [DeliveryInstructionsval, setDeliveryInstructionsval] = useState("");
  const [DeliveryInstructionsvallength, setDeliveryInstructionsvallength] =
    useState(400);

  const [LoadWalletBalance, setLoadWalletBalance] = useState(false);
  const [addbalancebox, setaddbalancebox] = React.useState(false);
  const [balanceamount, setbalanceamount] = React.useState("");
  const [balancecard, setbalancecard] = React.useState(null);
  const [balancecardholdername, setbalancecardholdername] =
    React.useState(null);
  const [balancecardexpmonth, setbalancecardexpmonth] = React.useState(null);
  const [balancecardexpyear, setbalancecardexpyear] = React.useState(null);
  const [balancecardcvv, setbalancecardcvv] = React.useState(null);
  const [dateselectsection, setdateselectsection] = React.useState(false);
  const [rentersummaymobile, setrentersummaymobile] = React.useState(false);
  const [tabviewcalendar, settabviewcalendar] = React.useState(1);
  const [LikeStatus, setLikeStatus] = React.useState(null);
  const [LikeLoading, setLikeLoading] = React.useState(false);

  const hoursArray = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  const [startHours, setStartHours] = useState(7);
  const [startMinutes, setStartMinutes] = useState("00");
  const [endHours, setEndHours] = useState(4);
  const [endMinutes, setEndMinutes] = useState("00");
  const [selectedStartTime, setSelectedStartTime] = useState("AM");
  const [selectedEndTime, setSelectedEndTime] = useState("PM");
  const [autoCompAddress, setAutoCompAddress] = useState("");

  const [ExtendRentalSummary, setExtendRentalSummary] = useState(false);
  const [APICallStatus, setAPICallStatus] = useState(false);
  const [MaxDate, setMaxDate] = useState([]);
  const [calenderMinDate, setCalenderMinDate] = useState(new Date());

  const [timeZone, setTimeZone] = useState("");

  const handleStartEndTime = (event, key) => {
    event.stopPropagation();
    switch (key) {
      // case "startHoursPlus": {
      //     setStartTime(
      //         `${startHours === 11 ? hoursArray[0] : hoursArray[startHours + 1]
      //         }:${startMinutes} ${selectedStartTime}`
      //     );
      //     startHours === 11 ? setStartHours(0) : setStartHours(startHours + 1);
      //     break;
      // }

      // case "startHoursMinus": {
      //     setStartTime(
      //         `${startHours === 0 ? hoursArray[11] : hoursArray[startHours - 1]
      //         }:${startMinutes} ${selectedStartTime}`
      //     );
      //     startHours === 0 ? setStartHours(11) : setStartHours(startHours - 1);
      //     break;
      // }

      // case "startMinutesToggle": {
      //     setStartTime(
      //         `${hoursArray[startHours]}:${startMinutes === "00" ? "30" : "00"
      //         } ${selectedStartTime}`
      //     );
      //     setStartMinutes(startMinutes === "00" ? "30" : "00");
      //     break;
      // }

      // case "toggleStartTime": {
      //     setStartTime(
      //         `${hoursArray[startHours]}:${startMinutes} ${selectedStartTime === "AM" ? "PM" : "AM"
      //         }`
      //     );
      //     setSelectedStartTime(selectedStartTime === "AM" ? "PM" : "AM");
      //     break;
      // }

      case "endHoursPlus": {
        setEndTime(
          `${
            endHours === 11 ? hoursArray[0] : hoursArray[endHours + 1]
          }:${endMinutes} ${selectedEndTime}`
        );
        endHours === 11 ? setEndHours(0) : setEndHours(endHours + 1);
        break;
      }

      case "endHoursMinus": {
        setEndTime(
          `${
            endHours === 0 ? hoursArray[11] : hoursArray[endHours - 1]
          }:${endMinutes} ${selectedEndTime}`
        );
        endHours === 0 ? setEndHours(11) : setEndHours(endHours - 1);
        break;
      }

      case "endMinutesToggle": {
        setEndTime(
          `${hoursArray[endHours]}:${
            endMinutes === "00" ? "30" : "00"
          } ${selectedEndTime}`
        );
        setEndMinutes(endMinutes === "00" ? "30" : "00");
        break;
      }

      case "toggleEndTime": {
        setEndTime(
          `${hoursArray[endHours]}:${endMinutes} ${
            selectedEndTime === "AM" ? "PM" : "AM"
          }`
        );
        setSelectedEndTime(selectedEndTime === "AM" ? "PM" : "AM");
        break;
      }
      default: {
        return;
      }
    }
  };

  var images = [];
  var images_gallery = [];

  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  const minimumDate = {
    year: year,
    month: month,
    day: day,
  };
  const defaultRange = {
    from: null,
    to: null,
  };
  const [selectedDay, setselectedDay] = useState(minimumDate);
  const [selectedDayRange, setSelectedDayRange] = useState(defaultRange);

  // Calendar Popup
  const [CalendarPopup, setCalendarPopup] = useState(false);
  const [CalendarPopupResult, setCalendarPopupResult] = useState({
    start: null,
    end: null,
  });
  // Transaport option popup
  const [transportoptionpopup, settransportoptionpopup] = useState(false);

  const [ExtendRentalRequestpopup, setExtendRentalRequestpopup] =
    useState(false);

  const bookedDates = [
    {
      end_date: "2024-11-21",
      end_time: "20:00:00",
      id: 672,
      start_date: "2024-11-20",
      start_time: "10:00:00",
    },
    {
      end_date: "2024-11-21",
      end_time: "20:00:00",
      id: 672,
      start_date: "2024-11-22",
      start_time: "10:00:00",
    },
  ];

  const [label, setLabel] = useState<string>("Select Drop-off Time");
  const [timeModalOpen, setTimeModalOpen] = useState<boolean>(false);
  const handleTimeModalOpen = () => setTimeModalOpen(true);
  const handleTimeModalClose = () => {
    if (equipRentedDates[1] && !equipRentedTimes?.endTime) {
      handleResetCalendar();
    }
    setTimeModalOpen(false);
  };
  const [equipRentedTimes, setEquipRentedTimes] = useState<any>({
    startTime: null,
    endTime: null,
  });
  const [equipRentedDates, setEquipRentedDates] = useState<any>([null, null]);
  const [minDateTime, setMinDateTime] = useState({
    minDate: new Date(),
    minTime: null,
    maxDate: null,
  });

  const [extendOrder, setExtendOrder] = useState<any>({});

  const [currentMonth, setCurrentMonth] = useState(new Date());

  const [messageOnError, setMessageOnError] = useState("");

  const [isNotClickableDate, setIsNotClickableDate] = useState("");

  const calendarRef = useRef(null);

  const handleTimeSelectionOnNext = () => {
    if (label?.includes("Drop-off") && !equipRentedTimes?.endTime) {
      // show alert
      dispatch(setalertMessage("Please select drop-off time to proceed."));
      dispatch(setalertMessagetype("error"));
      return false;
    }
    handleTimeModalClose();
  };

  const handleResetCalendar = () => {
    setEquipRentedTimes((prev) => ({ ...prev, endTime: null }));
    setEquipRentedDates((prev) => [prev[0], null]);
  };

  const handleNextSteps = () => {
    if (!equipRentedTimes?.endTime || !equipRentedDates[1]) {
      dispatch(
        setalertMessage("Please select end date and end time to proceed.")
      );
      dispatch(setalertMessagetype("error"));
      return false;
    }
    let diffMins = GetDifferenceinmin(startDate, endDate);
    if (diffMins && diffMins <= 0) {
      dispatch(setalertMessage("The selected pickup time is in the past."));
      dispatch(setalertMessagetype("error"));
      return false;
    }
    calculatePrice(startDate, endDate);
  };

  // Remove AM or PM from time string
  // const RemoveAMPMandTimeFormat = (time_str) => {
  //   let am_check = time_str.includes("AM");
  //   if (am_check) {
  //     time_str = time_str.slice(0, -3);
  //   }
  //   let pm_check = time_str.includes("PM");
  //   if (pm_check) {
  //     time_str = time_str.slice(0, -3);
  //     var new_time_arr = time_str.split(":");
  //     var new_time = parseInt(new_time_arr[0]);
  //     if (new_time_arr[0] != 12 || new_time_arr[0] != "12") {
  //       new_time = new_time + 12;
  //     }
  //     time_str = new_time + ":" + new_time_arr[1];
  //   }
  //   return time_str;
  // };

  // Calculate Rental price
  const calculatePrice = (__rentStartDate, __rentEndDate) => {
    if (__rentStartDate && __rentEndDate) {
      const _rentStartDate = moment(__rentStartDate).format("YYYY-MM-DD");
      const _rentEndDate = moment(__rentEndDate).format("YYYY-MM-DD");
      if (_rentStartDate == _rentEndDate && endTime < startTime) {
        dispatch(setalertMessage("The selected pickup time is invalid."));
        dispatch(setalertMessagetype("error"));
        setRentPrice(0);
        setSecurityDeposit(0);
        setEquipProtection(0);
        setRentalTotal(0);
        setdeliveryPrice(0);
        setgrandTotal(0);
        return false;
      }

      var st = moment(_rentStartDate + " " + startTime);
      var en = moment(_rentEndDate + " " + endTime);
      var difference = Math.abs(en.valueOf() - st.valueOf());

      var Difference_In_Days = difference / (1000 * 3600 * 24);
      var totalDays = Difference_In_Days.toFixed();
      setTotalDays(totalDays);
      let seconds = difference / 1000;
      var months = Math.floor(seconds / (3600 * 24 * 30));
      var weeks = Math.floor((seconds % (3600 * 24 * 30)) / (3600 * 24 * 7));
      var days = Math.floor(
        ((seconds % (3600 * 24 * 30)) % (3600 * 24 * 7)) / (3600 * 24)
      );
      var hours = Math.floor((seconds % (3600 * 24)) / 3600);
      if (months > 0 && weeks > 0) {
        days = days + weeks * 7;
        weeks = 0;
      }
      let monthPrice = parseFloat(extendOrder["price_month"]),
        weekPrice = parseFloat(extendOrder["price_per_week"]),
        dayPrice =
          months > 0 ? monthPrice / 30 : parseFloat(extendOrder["price_day"]),
        hourPrice = parseFloat(extendOrder["price_per_hour"]);
      var priceH = 0,
        priceD = 0,
        priceW = 0,
        priceM = 0;
      priceH = hours * hourPrice > dayPrice ? dayPrice : hours * hourPrice;
      if (months > 0) {
        priceD = days * dayPrice + priceH;
      } else {
        priceD =
          days * dayPrice + priceH > weekPrice
            ? weekPrice
            : days * dayPrice + priceH;
      }
      priceW =
        weeks * weekPrice + priceD > monthPrice
          ? monthPrice
          : weeks * weekPrice + priceD;
      priceM = months * monthPrice + priceW;
      var total = priceM;
      if (months == 0) {
        total = total > monthPrice ? monthPrice : total;
      }
      if (months == 0 && weeks == 0) {
        total = total > weekPrice ? weekPrice : total;
      }

      var new_total = parseFloat(total + "").toFixed(2);
      setRentPrice(new_total);
      let security_amt = (total / 100) * 20;
      let equipeprotection_amt = (total / 100) * 15;

      let p = equipeprotection_amt.toFixed(2);
      equipeprotection_amt = parseFloat(p);

      //let new_security_amt = parseFloat(security_amt+"").toFixed(2);

      let rental_total = total + security_amt + equipeprotection_amt;

      setRentalTotal(rental_total);
      setSecurityDeposit(security_amt);
      setEquipProtection(equipeprotection_amt);
      let transport_chrg = 0;
      if (chooseTransport == "owner") {
        transport_chrg =
          parseFloat(extendOrder["minimum_delivery_charges"]) +
          Number(deliveryPrice);
      }
      var grand_total = Number(rental_total) + Number(transport_chrg);
      setgrandTotal(grand_total);
      setExtendRentalSummary(true);
    } else {
      setExtendRentalSummary(false);
    }
  };

  // Check Date is Reserved or not
  // const isReserved = (strDate) => {
  //   // if (result["weekends"] == "0") {
  //   //     if (moment(strDate).format("ddd") == "Sat") {
  //   //         return true;
  //   //     }
  //   // }
  //   // if (result["sunday"] == "0") {
  //   //     if (moment(strDate).format("ddd") == "Sun") {
  //   //         return true;
  //   //     }
  //   // }

  //   if (blockdate.includes(strDate)) {
  //     return true;
  //   }
  //   return false;
  // };

  // Calculate  Difference in minutes bitween start and end dates
  const GetDifferenceinmin = (start, end) => {
    const startDateTime = moment(`${start} ${startTime}`, "YYYY-MM-DD HH:mm");
    const endDateTime = moment(`${end} ${endTime}`, "YYYY-MM-DD HH:mm");
    // Calculate the difference in minutes
    const diffInMinutes = endDateTime.diff(startDateTime, "minutes");
    return diffInMinutes;
  };

  // Place extended order
  const PlaceExtendOrder = async () => {
    setLoading(true);
    let grand_total = parseFloat(grandTotal.toFixed(2));
    let new_grand_total = parseFloat(grand_total + "").toFixed(2);

    let auth_user = localStorage.getItem("token-info");
    auth_user = JSON.parse(auth_user);
    let user_id = auth_user["user_id"].toString();
    let balance = Number(WalletBalance);
    let date = moment(startDate + "T" + startTime, "YYYY-MM-DDTHH:mm").utc();
    let end_time_utc = moment(endDate + "T" + endTime, "YYYY-MM-DDTHH:mm")
      .utc()
      .toDate()
      .getTime();
    let order_amount =
      Number(rentalTotal) - Number(securityDeposit) - Number(equipProtection);
    let new_order_amount = parseFloat(order_amount + "").toFixed(2);

    let new_securityDeposit = parseFloat(securityDeposit + "").toFixed(2);
    let new_equipProtection = parseFloat(equipProtection + "").toFixed(2);

    let start_Date = moment(startDate).format("YYYY-MM-DD");
    let end_Date = moment(endDate).format("YYYY-MM-DD");

    let orderData = {
      user_id: user_id,
      equip_id: ActiveOrder["id"].toString(),
      txn_id: Date.now().toString(),
      order_details: "test",
      status: "1",
      amount: new_order_amount,
      start_date: start_Date.toString(),
      end_date: end_Date.toString(),
      start_time: startTime.toString(),
      end_time: endTime.toString(),
      instruction: "",
      total_days: TotalDays.toString(),
      equip_user_id: ActiveOrder["user_id"].toString(),
      order_date: date,
      total_price: new_grand_total.toString(),
      security_deposit: new_securityDeposit.toString(),
      equipment_protection_fees: new_equipProtection.toString(),
      end_time_utc: end_time_utc,
      order_from: "web",
      parent_order_id: ActiveOrder["order_id"].toString(),
      // Added for card payment hold
      payment_hold: false,
      CardObj: {},
      is_expire: true,
    };
    // console.log('orderData', orderData);
    // return;

    /**
     * Changes for card payment holding
     * Section start
     */
    setorderRequestData(orderData);
    let remaining = 0;
    if (balance != null && balance < grand_total) {
      remaining = grand_total - balance;
      setholdAmount(remaining);
    }

    if (remaining != 0) {
      setLoading(false);
      setpayWithCard(true);
    } else {
      /**
       * Changes for card payment holding
       * Section end
       */
      if (grand_total > balance) {
        dispatch(
          setalertMessage("Insufficient wallet balance. please add balance.")
        );
        dispatch(setalertMessagetype("error"));
        setLoading(false);
        return false;
      }

      let headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const orderResult: IApiResponseRawData =
        await EquipmentAPIs.ExtendedOrderPlace(orderData, headers);

      if (orderResult["type"] == "RXSUCCESS") {
        if (!setLocal) {
          localStorage.setItem("OrderPlacedId", ActiveOrder["order_id"]);
        }

        // dispatch(setalertMessage(orderResult["message"]));
        // dispatch(setalertMessagetype("success"));
        //Result('closepopup');
        // by GD 5 jan to reload the orders page
        Result("orderExtended");
        // end by GD
        setExtendRentalRequestpopup(true);
        setLoading(false);
      } else if (
        orderResult["status"] == false &&
        orderResult["slug"] == "Session Expired"
      ) {
        Result("closepopup");
        dispatch(setSessionExpire(true));
        return false;
      } else {
        setLoading(false);
        dispatch(setalertMessage(orderResult["message"]));
        dispatch(setalertMessagetype("error"));
      }
      /**
       * Changes for card payment holding
       * Section start
       */
    }
    /**
     * Changes for card payment holding
     * Section end
     */
  };

  // Extend Order Section start
  // const getDates = (startDate, stopDate) => {
  //   var dateArray = [];
  //   var currentDate = moment(startDate);
  //   var stop_Date = moment(stopDate);
  //   while (currentDate <= stop_Date) {
  //     dateArray.push(moment(currentDate).format("YYYY-MM-DD"));
  //     currentDate = moment(currentDate).add(1, "days");
  //   }
  //   return dateArray;
  // };

  const getPartialDates = ({ startDate, startTime, endDate, endTime }) => {
    if (
      startDate === endDate &&
      (startTime !== "00:00:00" || endTime !== "23:30:00")
    ) {
      return {
        halfStart: {
          startDate,
          endDate,
          startTime,
          endTime,
        },
        halfEnd: null,
      };
    } else {
      const partialDate = { halfStart: null, halfEnd: null };
      if (startTime !== "00:00:00") {
        partialDate["halfStart"] = {
          startDate,
          endDate: startDate,
          startTime,
          endTime: "23:30:00",
        };
      }
      if (endTime !== "23:30:00") {
        partialDate["halfEnd"] = {
          startDate: endDate,
          endDate,
          startTime: "00:00:00",
          endTime,
        };
      }
      return partialDate;
    }
  };

  const handleDateChanges = ({
    blockedDates,
    bookedDates,
    minDate,
    maxDate,
    calendarStartDateTime,
  }) => {
    const modifiedBlockedDates = [];
    blockedDates.forEach((element) => {
      if (element > minDate && (!maxDate || element <= maxDate)) {
        modifiedBlockedDates.push(element);
      }
    });
    const partiallySetBookedStart = new Set();
    const partiallySetBookedEnd = new Set();
    const partiallySetPendingStart = new Set();
    const partiallySetPendingEnd = new Set();

    const partiallyBookedStart = [];
    const partiallyBookedEnd = [];
    const partiallyPendingStart = [];
    const partiallyPendingEnd = [];

    const bookedDateArray = [];
    const requestedDates = [];
    bookedDates.forEach((dateElement) => {
      if (!maxDate || dateElement?.start_date <= maxDate) {
        const dateRange = getDatesInRange(
          dateElement?.start_date,
          dateElement?.end_date
        );
        if (dateElement?.order_confirm_status === "1") {
          bookedDateArray.push(...dateRange);
        } else {
          requestedDates.push(...dateRange);
        }
        const { halfEnd, halfStart } = getPartialDates({
          startDate: dateElement?.start_date,
          startTime: dateElement?.start_time,
          endDate: dateElement?.end_date,
          endTime: dateElement?.end_time,
        });
        if (halfStart) {
          if (dateElement?.order_confirm_status === "1") {
            partiallyBookedStart.push({
              ...halfStart,
              status: "booked",
            });
            if (
              halfStart?.startDate &&
              !partiallySetBookedStart.has(halfStart?.startDate)
            ) {
              partiallySetBookedStart.add(halfStart?.startDate);
            }
          } else {
            partiallyPendingStart.push({ ...halfStart, status: "requested" });
            if (
              halfStart?.startDate &&
              !partiallySetPendingStart.has(halfStart?.startDate)
            ) {
              partiallySetPendingStart.add(halfStart?.startDate);
            }
          }
        }
        if (halfEnd) {
          if (dateElement?.order_confirm_status === "1") {
            partiallyBookedEnd.push({
              ...halfEnd,
              status: "booked",
            });
            if (
              halfEnd?.endDate &&
              !partiallySetBookedEnd.has(halfEnd?.endDate)
            ) {
              partiallySetBookedEnd.add(halfEnd?.endDate);
            }
          } else {
            if (halfStart) {
              partiallyPendingStart.push({ ...halfStart, status: "requested" });
              if (
                halfStart?.startDate &&
                !partiallySetPendingStart.has(halfStart?.startDate)
              ) {
                partiallySetPendingStart.add(halfStart?.startDate);
              }
            }
            if (halfEnd) {
              partiallyPendingEnd.push({ ...halfEnd, status: "requested" });
              if (
                halfEnd?.endDate &&
                !partiallySetPendingEnd.has(halfEnd?.endDate)
              ) {
                partiallySetPendingEnd.add(halfEnd?.endDate);
              }
            }
          }
        }
      }
    });

    const partialDatesArray = [
      ...Array.from(new Set(partiallySetBookedStart)),
      ...Array.from(new Set(partiallySetBookedEnd)),
      ...Array.from(new Set(partiallySetPendingStart)),
      ...Array.from(new Set(partiallySetPendingEnd)),
    ];

    const partialBookingDatesCount = {};
    partialDatesArray.forEach((date: string) => {
      partialBookingDatesCount[date] =
        (partialBookingDatesCount[date] || 0) + 1;
    });
    const allPartialDatesTimes = [
      ...partiallyBookedStart,
      ...partiallyBookedEnd,
      ...partiallyPendingStart,
      ...partiallyPendingEnd,
    ];
    const allPartialDates = new Set();
    allPartialDatesTimes.forEach(({ startDate }) => {
      if (!allPartialDates.has(startDate)) {
        allPartialDates.add(startDate);
      }
    });
    const generatedKeyValues = {};
    const calendarDateAngle = {};
    allPartialDates.forEach((date) => {
      const allGeneratedSlots = getAllSlotsForDate({
        date,
        reservedDate: allPartialDatesTimes,
      });
      const dateAngle = getDateAngles({ date, allPartialDatesTimes });
      calendarDateAngle[`${date}`] = dateAngle;
      const count = Object.entries(allGeneratedSlots)?.reduce((sum, item) => {
        if (!item[1]["disable"]) {
          return sum + 1;
        }
        return sum;
      }, 0);
      if (count >= 1) {
        generatedKeyValues[`${date}`] = allGeneratedSlots;
      }
    });

    if (generatedKeyValues[calendarStartDateTime?.startDate]) {
      const generatedVal =
        generatedKeyValues[calendarStartDateTime?.startDate][
          calendarStartDateTime?.startTime
        ];
      generatedVal["disable"] = false;
      generatedVal["status"] = "default";
    }
    if (maxDate && generatedKeyValues[maxDate]) {
      const MaxKeyValue = generatedKeyValues[maxDate];
      const timeSlots = Object.keys(MaxKeyValue);
      const startIndex = [...timeSlots]
        ?.reverse()
        ?.findIndex((slots) => MaxKeyValue[slots]["disable"]);
      for (let i = 48 - startIndex; i < 48; i++) {
        MaxKeyValue[timeSlots[i]]["disable"] = true;
      }
    }
    dispatch(setEquipmentRequestedDates(Array.from(new Set(requestedDates))));
    dispatch(setEquipmentBookedDates(Array.from(new Set(bookedDateArray))));
    dispatch(setEquipmentBlockedDates(modifiedBlockedDates));
    dispatch(setAllPartialDates(Array.from(allPartialDates)));
    dispatch(setReservedDates([generatedKeyValues]));
    dispatch(setCalendarDateAngle(calendarDateAngle));
    dispatch(
      setPartiallyBookedDates([
        Array.from(partiallySetBookedStart),
        Array.from(partiallySetBookedEnd),
      ])
    );
    dispatch(
      setPartiallyPendingDates([
        Array.from(partiallySetPendingStart),
        Array.from(partiallySetPendingEnd),
      ])
    );
    dispatch(setPartialDatesCount(partialBookingDatesCount));
    setAPICallStatus(true);
  };

  function addHalfHour(time) {
    // Split the input time to get hours and minutes
    let [hours, minutes] = time.split(":").map(Number);

    // Create a Date object with the current date and provided time
    let date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);

    // Add 30 minutes
    date.setMinutes(date.getMinutes() + 30);

    // Format the new time back to 24-hour format
    let updatedHours = String(date.getHours()).padStart(2, "0");
    let updatedMinutes = String(date.getMinutes()).padStart(2, "0");

    return `${updatedHours}:${updatedMinutes}:00`;
  }

  const checkExpiration = (datetime) => {
    const timezoneFull = ActiveOrder["timezone"] || "UTC";
    const givenDateTime = momentTimeZone.tz(
      datetime,
      "YYYY-MM-DD HH:mm:ss",
      timezoneFull
    );
    const currentDateTime = momentTimeZone.tz(timezoneFull);
    const isExpire = givenDateTime.isBefore(currentDateTime);
    const currentMinutes = currentDateTime.minutes();
    if (currentMinutes <= 29) {
      currentDateTime.minutes(30).seconds(0); // Set minutes to 30
    } else {
      currentDateTime.minutes(0).seconds(0).add(1, "hour"); // Set minutes to 00 and add 1 hour
    }
    const startDate = currentDateTime.format("YYYY-MM-DD");
    const startTime = currentDateTime.format("HH:mm:ss");
    return {
      isExpire: isExpire,
      startDate: startDate,
      startTime: startTime,
    };
  };

  const APIGetOrderedDates = async (equipId, orderId) => {
    try {
      if (!equipId || !orderId) {
        return false;
      }
      setLoading(true);
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const params = {
        equip_id: equipId?.toString(),
        order_id: orderId?.toString(),
      };
      const resultData = await EquipmentAPIs.getExtendOrderDetail(
        params,
        headers
      );
      if (resultData["type"] == "RXSUCCESS") {
        if (resultData["data"]?.["main_order"]?.length < 1) {
          throw new Error("Something went wrong! Please try again.");
        }
        const blockedDates = resultData["data"]?.["block_dates"]?.split(",");
        // ?.slice(1);
        let maxDate = null;
        const { currentDate } = convertTimeZone(timeZone);
        let currentFormattedDate = moment(currentDate).format("YYYY-MM-DD");
        let minDate = resultData["data"]?.["main_order"][0]["start_date"];
        if (
          currentFormattedDate >
          resultData["data"]?.["main_order"][0]["end_date"]
        ) {
          minDate = currentFormattedDate;
        }

        // if (resultData["data"]?.["max_date"]?.length) {
        //   const maxDateData = resultData["data"]?.["max_date"][0];
        //   if (
        //     maxDateData?.type === "extendOrder" &&
        //     (!maxDate || (maxDate && maxDateData?.["end_date"] < maxDate))
        //   ) {
        //     maxDate = maxDateData?.["end_date"];
        //   } else if (
        //     maxDateData?.type === "otherOrder" &&
        //     (!maxDate || (maxDate && maxDateData?.["start_date"] < maxDate))
        //   ) {
        //     maxDate = maxDateData?.["start_date"];
        //   }
        // }
        // console.log("maxDate", maxDate);
        // if (maxDate < resultData["data"]?.["main_order"][0]["end_date"]) {
        //   maxDate = null;
        // }
        dispatch(
          setRentOnWeekend({
            sunday: resultData["data"]["sunday"],
            saturday: resultData["data"]["weekends"],
          })
        );
        const validExtendOrder = resultData["data"]?.["extend_order"]?.filter(
          (order) => order?.order_confirm_status === "0"
        );

        // new DateObject({ date: "2024/12/24", format: "YYYY/MM/DD" })
        let calendarStartDateTime = {
          startDate: resultData["data"]?.["main_order"][0]["end_date"],
          // startTime: addHalfHour(resultData["data"]?.["main_order"][0]["end_time"]),
          startTime: resultData["data"]?.["main_order"][0]["end_time"],
        };

        resultData["data"]?.["extend_order"]?.forEach((dateElement) => {
          if (dateElement?.order_confirm_status === "0") {
            calendarStartDateTime.startDate = dateElement?.end_date;
            // calendarStartDateTime.startTime = addHalfHour(dateElement?.end_time?.split(":"));
            calendarStartDateTime.startTime = dateElement?.end_time;
          }
        });

        const {
          startDate: newDate,
          startTime: newTime,
          isExpire,
        } = checkExpiration(
          `${calendarStartDateTime.startDate} ${calendarStartDateTime.startTime}`
        );
        if (isExpire) {
          calendarStartDateTime.startDate = newDate;
          calendarStartDateTime.startTime = newTime;
        }

        // below loop is find future mindate from calendar startDate
        for (const date of blockedDates) {
          if (
            date &&
            date >= calendarStartDateTime.startDate &&
            (!maxDate || date <= maxDate)
          ) {
            maxDate = date;
          }
        }

        if (resultData["data"]?.["max_date"]?.length) {
          const maxDateData = resultData["data"]?.["max_date"][0];
          if (!maxDate || maxDateData?.["end_date"] < maxDate) {
            maxDate = maxDateData?.["end_date"];
          }
          if (
            maxDateData?.type === "extendOrder" ||
            (maxDateData?.type === "otherOrder" &&
              maxDateData?.["start_date"] !== maxDateData?.["end_date"])
          ) {
            setIsNotClickableDate(maxDateData?.["end_date"]);
          }
          // if (
          //   maxDateData?.type === "extendOrder" &&
          //   (!maxDate || maxDateData?.["end_date"] < maxDate)
          // ) {
          //   maxDate = maxDateData?.["end_date"];
          //   setIsNotClickableDate(maxDateData?.["end_date"]);
          // } else if (
          //   maxDateData?.type === "otherOrder" &&
          //   (!maxDate || maxDateData?.["start_date"] < maxDate)
          // ) {
          //   // maxDate = maxDateData?.["start_date"];
          //   maxDate = maxDateData?.["end_date"];
          // }
        }
        console.log("calendarStartDateTime", calendarStartDateTime);
        console.log("maxDate and minDate", maxDate, minDate);

        if (isExpire && maxDate && maxDate <= calendarStartDateTime.startDate) {
          throw new Error("Oops, Rental is no longer available to extend.");
        }
        // if(calendarRef.current && calendarStartDateTime?.startDate) {
        //   const [year,month,day] = calendarStartDateTime.startDate?.split("-");
        //   const zeroBasedMonth = parseInt(month, 10) - 1;
        //   setCurrentMonth(calendarStartDateTime.startDate)
        //   calendarRef.current.set("month",zeroBasedMonth);
        //   calendarRef.current.set("year",year);
        // }
        setMinDateTime({
          minDate,
          maxDate,
          minTime: resultData["data"]?.["main_order"][0]["start_time"],
        });
        setEquipRentedDates([
          new DateObject({
            date: calendarStartDateTime.startDate?.replaceAll("-", "/"),
            format: "YYYY/MM/DD",
          }),
          null,
        ]);
        setEquipRentedTimes({
          startTime: calendarStartDateTime.startTime,
          endTime: null,
        });
        setExtendOrder(resultData["data"]?.["equip_detail"]);
        const bookedDates = [
          ...resultData["data"]?.["main_order"],
          ...validExtendOrder,
          ...resultData["data"]?.["max_date"],
        ];
        handleDateChanges({
          blockedDates,
          bookedDates,
          maxDate,
          minDate,
          calendarStartDateTime,
        });
      } else {
        throw new Error(resultData["message"]);
      }
    } catch (error) {
      setMessageOnError(error["message"] || "An error occurred!");
      dispatch(setalertMessage(error["message"]));
      dispatch(setalertMessagetype("error"));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // console.log("active=================>",ActiveOrder);
    //added by pp 0n 25 apr 2024 due to timezone changes
    async function fetchTimeZone() {
      let timeZone = ActiveOrder["timezone"];
      if (!timeZone) {
        const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
        const timeZoneData = await EquipmentAPIs.getEquipTimeZone(
          { equip_id: ActiveOrder["id"] },
          headers
        );
        if (timeZoneData.type === "RXSUCCESS") {
          timeZone = timeZoneData.data;
        }
      }
      setTimeZone(
        convertTimeZoneShortName(
          momentTimeZone()
            .tz(timeZone ?? "UTC")
            .zoneAbbr()
        )
      );
    }
    // end by pp
    if (Object.entries(ActiveOrder).length) {
      setLoading(true);
      APIGetOrderedDates(ActiveOrder["id"], ActiveOrder["order_id"]);
      fetchTimeZone();
    }
    dispatch(setReservedDates([{}]));
  }, [ActiveOrder, AuthToken]);

  useEffect(() => {
    setEndDate(equipRentedDates[1]?.format("YYYY/MM/DD"));
    setStartDate(equipRentedDates[0]?.format("YYYY/MM/DD"));
    setStartTime(equipRentedTimes?.startTime);
    setEndTime(equipRentedTimes?.endTime);
    if (equipRentedDates[1]) {
      handleTimeModalOpen();
    }
  }, [equipRentedDates, equipRentedTimes]);

  interface MyPluginProps {
    position: string;
  }

  // function MyPlugin(props: MyPluginProps) {
  //   return (
  //     <>
  //       <Box className="time-box">
  //         <Box className="time-main-box">
  //           <FormLabel className="time-box-label">
  //             Return Time ({timeZone}) -{" "}
  //           </FormLabel>
  //           <Box className="time-main-box-top">
  //             <Button
  //               className="plus-btn"
  //               variant="contained"
  //               onClick={(event) => {
  //                 handleStartEndTime(event, "endHoursPlus");
  //                 //calculatePrice(startDate, endDate);
  //               }}
  //             >
  //               <KeyboardArrowUpIcon />
  //             </Button>
  //             <TextField
  //               id="outlined-disabled"
  //               value={hoursArray[endHours]}
  //               disabled
  //               className="cal-time-input"
  //               sx={{
  //                 "& .MuiSelect-select": {
  //                   color: "#2D2D2D !important",
  //                   fontFamily: "Gotham !important",
  //                 },
  //               }}
  //               style={{
  //                 backgroundColor: "#fff",
  //                 border: "1px solid #1976d2",
  //                 borderRadius: "10px",
  //               }}
  //             />
  //             <Button
  //               className="minus-btn"
  //               variant="contained"
  //               onClick={(event) => {
  //                 handleStartEndTime(event, "endHoursMinus");
  //                 //calculatePrice(startDate, endDate);
  //               }}
  //             >
  //               <KeyboardArrowDownIcon />
  //             </Button>
  //           </Box>

  //           <Typography sx={{ color: "#fff !important" }}>:</Typography>

  //           <Box className="time-main-box-mid">
  //             <Button
  //               className="plus-btn"
  //               variant="contained"
  //               onClick={(event) => {
  //                 handleStartEndTime(event, "endMinutesToggle");
  //                 // calculatePrice(startDate, endDate);
  //               }}
  //             >
  //               <KeyboardArrowUpIcon />
  //             </Button>
  //             <TextField
  //               id="outlined-disabled"
  //               value={endMinutes}
  //               disabled
  //               className="cal-time-input"
  //               sx={{
  //                 "& .MuiSelect-select": {
  //                   color: "#2D2D2D !important",
  //                   fontFamily: "Gotham !important",
  //                 },
  //               }}
  //               style={{
  //                 backgroundColor: "#fff",
  //                 border: "1px solid #1976d2",
  //                 borderRadius: "10px",
  //               }}
  //             />
  //             <Button
  //               className="minus-btn"
  //               variant="contained"
  //               onClick={(event) =>
  //                 handleStartEndTime(event, "endMinutesToggle")
  //               }
  //             >
  //               <KeyboardArrowDownIcon />
  //             </Button>
  //           </Box>
  //           <Box className="time-main-box-btm">
  //             {/* <Button variant="contained" onClick={(event) => handleStartEndTime(event, "toggleEndTime")}>
  //                 {selectedEndTime}
  //               </Button> */}
  //             <ToggleButtonGroup
  //               orientation="vertical"
  //               value={selectedEndTime}
  //               exclusive
  //               onChange={(event) => {
  //                 handleStartEndTime(event, "toggleEndTime");
  //                 //calculatePrice(startDate, endDate);
  //               }}
  //             >
  //               <ToggleButton
  //                 className={
  //                   selectedEndTime === "AM" ? "am-pm active" : "am-pm"
  //                 }
  //                 value="AM"
  //                 aria-label="list"
  //               >
  //                 AM
  //               </ToggleButton>
  //               <ToggleButton
  //                 className={
  //                   selectedEndTime === "PM" ? "am-pm active" : "am-pm"
  //                 }
  //                 value="PM"
  //                 aria-label="module"
  //               >
  //                 PM
  //               </ToggleButton>
  //             </ToggleButtonGroup>
  //           </Box>
  //         </Box>
  //       </Box>
  //       <Box className="confirm-div">
  //         {startDate && endDate && (
  //           <Button
  //             className="confirm-btns extend-confirm-btn"
  //             variant="contained"
  //             onClick={() => {
  //               let diffMins = GetDifferenceinmin(startDate, endDate);
  //               if (diffMins === null || diffMins == 0) {
  //                 dispatch(
  //                   setalertMessage("The selected pickup time is in the past.")
  //                 );
  //                 dispatch(setalertMessagetype("error"));
  //                 return false;
  //               }
  //               calculatePrice(startDate, endDate);
  //             }}
  //           >
  //             Confirm
  //           </Button>
  //         )}
  //       </Box>
  //     </>
  //   );
  // }

  /**
   * Direct pay with card and payment hold
   * section start
   */
  const payWithCardClose = () => {
    setpayWithCard(false);
    setLoading(false);
  };

  const HandleCardHoldPayment = async (cardObjData) => {
    try {
      let final = {
        ...orderRequestData,
        CardObj: cardObjData,
        payment_hold: true,
        is_expire: true,
      };

      let headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const orderResult: IApiResponseRawData =
        await EquipmentAPIs.ExtendedOrderPlace(final, headers);

      if (orderResult["type"] == "RXSUCCESS") {
        if (!setLocal) {
          localStorage.setItem("OrderPlacedId", ActiveOrder["order_id"]);
        }
        Result("orderExtended");
        setExtendRentalRequestpopup(true);
        setLoading(false);
        setpayWithCard(false);
      } else if (
        orderResult["status"] == false &&
        orderResult["slug"] == "Session Expired"
      ) {
        Result("closepopup");
        dispatch(setSessionExpire(true));
        return false;
      } else {
        setLoading(false);
        dispatch(setalertMessage(orderResult["message"]));
        dispatch(setalertMessagetype("error"));
      }
    } catch (err) {
      console.log(err);
    }
  };
  /**
   * Direct pay with card and payment hold
   * section start
   */
  return (
    <>
      {loading && (
        <Box>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Box>
      )}
      {messageOnError && (
        <Typography
          sx={{ color: "white", fontSize: "1.2rem", textAlign: "center" }}
        >
          {messageOnError}
        </Typography>
      )}

      {APICallStatus && (
        <Box
          // className="twin-cal"
          className="extend-rent-calendar"
          style={{ display: ExtendRentalSummary ? "none" : "block" }}
        >
          {/* <Box className="new-modal-heading">
            <Typography variant="h5">
              <Box component={"span"} sx={{ color: "#faa61a" }}>
                Availability Calender
              </Box>
            </Typography>
          </Box> */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "1.5rem",
            }}
          >
            <Box sx={{ textAlign: "center", cursor: "pointer" }}>
              <Typography
                sx={{
                  color: "#fff",
                  fontWeight: "800",
                  fontFamily: "Montserrat !important",
                  fontSize: "16px",
                }}
              >
                {!equipRentedDates[0]
                  ? "Start"
                  : moment(equipRentedDates[0].format("YYYY-MM-DD")).format(
                      "ddd, MMM D"
                    )}
              </Typography>
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: "14px",
                  fontFamily: "Montserrat !important",
                }}
              >
                {equipRentedTimes?.startTime
                  ? `${moment(equipRentedTimes?.startTime, "HH:mm").format(
                      "hh:mm A"
                    )} (${timeZone})`
                  : `Start Time (${timeZone})`}
              </Typography>
            </Box>
            <LabelImportantIcon
              sx={{
                width: "30px",
                height: "auto",
                color: "#faa61a",
              }}
            />
            <Box sx={{ textAlign: "center", cursor: "pointer" }}>
              <Typography
                sx={{
                  color: "#fff",
                  fontWeight: "800",
                  fontFamily: "Montserrat !important",
                  fontSize: "16px",
                }}
              >
                {!equipRentedDates[1]
                  ? "End"
                  : moment(equipRentedDates[1].format("YYYY-MM-DD")).format(
                      "ddd, MMM D"
                    )}
              </Typography>
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: "14px",
                  fontFamily: "Montserrat !important",
                }}
              >
                {equipRentedTimes?.endTime
                  ? `${moment(equipRentedTimes?.endTime, "HH:mm").format(
                      "hh:mm A"
                    )} (${timeZone})`
                  : `End Time (${timeZone})`}
              </Typography>
            </Box>
          </Box>
          <Box>
            <ExtendRentalCalendar
              equipRentedDates={equipRentedDates}
              setEquipRentedDates={setEquipRentedDates}
              minDateTime={minDateTime}
              equipRentedTimes={equipRentedTimes}
              setCurrentMonth={setCurrentMonth}
              calendarRef={calendarRef}
              isNotClickableDate={isNotClickableDate}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "1rem",
              mt: 2,
              mb: 1,
            }}
          >
            <Button
              sx={{
                background: "none",
                fontFamily: "Montserrat !important",
                fontWeight: "800",
                fontSize: {
                  xs: "14px",
                  sm: "15px",
                  md: "16px",
                  lg: "17px",
                  xl: "18px",
                },
                color: "#faa61a",
                textTransform: "capitalize",
                padding: "2.5px 20px",
                "&:hover": {
                  background: "none",
                },
                textDecoration: "underline",
              }}
              onClick={handleResetCalendar}
            >
              Reset
            </Button>
            <Button
              sx={{
                background: "#179346",
                fontFamily: "Montserrat !important",
                fontWeight: "800",
                fontSize: {
                  xs: "14px",
                  sm: "15px",
                  md: "16px",
                  lg: "17px",
                  xl: "18px",
                },
                color: "#fff",
                textTransform: "capitalize",
                padding: "2.5px 20px",
                "&:hover": {
                  background: "green",
                },
              }}
              onClick={handleNextSteps}
            >
              Next
            </Button>
          </Box>
        </Box>
      )}
      {ExtendRentalSummary && !ExtendRentalRequestpopup && !payWithCard && (
        <>
          <Box className="">
            <Box className="new-modal-heading">
              <img
                src={leftarrow}
                alt=""
                style={{ width: "9%", cursor: "pointer" }}
                onClick={() => {
                  setExtendRentalSummary(false);
                }}
              />
              <Typography variant="h5">
                <Box component={"span"} sx={{ color: "#faa61a" }}>
                  EXTENDED RENTAL SUMMARY
                </Box>
              </Typography>
            </Box>

            <Box className="rentyrd-dates">
              <Box className="er-grid">
                <Box className="er-item">
                  <Typography>
                    <Box component={"span"} sx={{ color: "#fff" }}>
                      Rental Price :
                    </Box>
                    <Box component={"span"} sx={{ color: "#fff" }}>
                      $
                      {new Intl.NumberFormat("en-US", {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      }).format(rentPrice)}
                    </Box>
                  </Typography>
                  <Typography>
                    <Box component={"span"} sx={{ color: "#fff" }}>
                      Security Deposit :
                    </Box>
                    <Box component={"span"} sx={{ color: "#fff" }}>
                      $
                      {new Intl.NumberFormat("en-US", {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      }).format(securityDeposit)}
                    </Box>
                  </Typography>
                  <Typography>
                    <Box component={"span"} sx={{ color: "#fff" }}>
                      EquipMe Protection :
                    </Box>
                    <Box component={"span"} sx={{ color: "#fff" }}>
                      $
                      {new Intl.NumberFormat("en-US", {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      }).format(equipProtection)}
                    </Box>
                  </Typography>
                  {/* <Typography>
                                        <span style={{ color: "#faa61a" }}>Total: </span>
                                        <span>
                                            $ {parseFloat(grandTotal + "").toFixed(2)}
                                        </span>
                                    </Typography> */}
                </Box>

                <Box className="er-item">
                  <Typography
                    sx={{
                      color: "#faa61a !important",
                      fontSize: "clamp(20px, 3vw, 20px)",
                      fontFamily: "'GothamBold' !important",
                    }}
                  >
                    Extended Dates
                  </Typography>
                  <Box className="rentyrd-dates-item rental-dates">
                    <Typography>
                      <Box component={"span"} sx={{ color: "#fff" }}>
                        Start:
                      </Box>
                      <Box component={"span"} sx={{ color: "#fff" }}>
                        {moment(startDate).format("MM/DD/YYYY")}{" "}
                        {moment(startTime, "HH:mm").format("hh:mm A")}
                        &nbsp;({timeZone})
                      </Box>
                    </Typography>
                    <Typography>
                      <Box component={"span"} sx={{ color: "#fff" }}>
                        End:
                      </Box>
                      <Box component={"span"} sx={{ color: "#fff" }}>
                        {/* {moment(endDate, [
                                                    "YYYY-MM-DD",
                                                ]).format("MM/DD/YYYY")}{" "}
                                                {endTime} */}
                        {moment(endDate, ["YYYY-MM-DD"]).format("MM/DD/YYYY")}{" "}
                        {moment(endTime, "HH:mm").format("hh:mm A")}
                        &nbsp;({timeZone})
                      </Box>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className="proceed-sec">
            <Box className="total-amount-div">
              <Typography>Total Amount to Pay</Typography>
              <Typography className="total-value-text">
                $
                {new Intl.NumberFormat("en-US", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }).format(grandTotal)}
              </Typography>
            </Box>
          </Box>
          <Box className="submitpayment-btn-div">
            <Button
              className="submitpayment-btn"
              onClick={() => {
                PlaceExtendOrder();
              }}
            >
              SUBMIT
            </Button>
          </Box>
        </>
      )}

      {ExtendRentalRequestpopup && (
        <Box className="">
          Thank you, you will not be charged until the owner of this equipment
          approves your rental request. Keep an eye on your email for
          confirmation. Also, please reach out to the owner directly at this
          <Box
            component={"span"}
            sx={{
              textDecoration: "underline",
              color: "#fba618",
              cursor: "pointer",
              marginLeft: "7px",
            }}
            onClick={() => handleChatMessage(ActiveOrder)}
          >
            link
          </Box>{" "}
          with questions. Make sure you have your notifications turned on in
          your GoEquipMe settings.
        </Box>
      )}

      {/* Order amount pay direct with card */}
      <Modal open={payWithCard} className="addmoney-modal">
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            padding: "0px",
          }}
        >
          <IconButton className="cross-btn" onClick={payWithCardClose}>
            <CloseIcon />
          </IconButton>

          <Box className="login__main">
            <Box className="login__main-div edit-eqp">
              <Box
                className="login__main-div__form popup-cmmn popup-white edit-eqp-inner"
                style={{
                  border: "5px solid #faa61a",
                  borderRadius: "10px",
                }}
              >
                <Typography
                  variant="h5"
                  style={{ color: "#faa61a", marginBottom: "0.5rem" }}
                >
                  Pay With Card
                </Typography>

                <PaymentHold
                  amount={holdAmount}
                  onSuccess={(e) => {
                    if (e.status == "success") {
                      HandleCardHoldPayment(e);
                    }
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
      <CustomTimeModal
        open={timeModalOpen}
        handleClose={handleTimeModalClose}
        label={"Select Drop-off Time"}
        timeZoneAbbr={timeZone || "UTC"}
        equipRentedTimes={equipRentedTimes}
        setEquipRentedTimes={setEquipRentedTimes}
        minDateTime={minDateTime}
        equipRentedDates={equipRentedDates}
        openInModal={true}
        handleTimeSelectionOnNext={handleTimeSelectionOnNext}
        //    setTempRentalTimes={setTempRentalTimes}
        modalOpen={timeModalOpen}
        extendIsOpen={true}
      />
    </>
  );
};

export default ExtendRental;
